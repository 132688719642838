import {
  createContext,
  useContext,
  useCallback,
  useMemo,
  useState,
} from 'react'

export const FormContext = createContext({})

export const useFormProvider = (onSubmit, validator) => {
  const [values, setValues] = useState({})
  const [errors, setErrors] = useState({})

  const hasErrors = useMemo(() => Object.keys(errors).length > 0, [errors])

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault()
      setErrors({})

      const { grecaptcha, env } = window
      const csrfToken = env?.csrfToken
      const recaptchaKey = env?.recaptchaKey

      if (recaptchaKey) {
        await new Promise((resolve) => {
          grecaptcha.ready(resolve)
        })
      }

      const recaptchaToken = recaptchaKey
        ? await grecaptcha.execute(recaptchaKey, { action: 'submit' })
        : null

      let allErrors = {}
      if (typeof validator === 'function') {
        allErrors = validator(values)
        setErrors(allErrors)
      }

      if (Object.keys(allErrors).length === 0) {
        const resp = await onSubmit(values, e, {
          csrfToken,
          recaptchaToken,
        })
        if (resp) {
          if (typeof resp === 'string') {
            setErrors({
              GLOBAL: resp,
            })
          } else if (typeof resp === 'object') {
            setErrors(resp)
          }
        }
      }

      return errors
    },
    [onSubmit, values, validator, errors]
  )

  const setValue = useCallback(
    (key, value) =>
      setValues((prevValues) => ({
        ...prevValues,
        [key]: typeof value === 'string' ? value.trimStart() : value,
      })),
    [setValues]
  )

  return {
    values,
    setValue,
    errors,
    hasErrors,
    handleSubmit,
  }
}

export const useForm = () => useContext(FormContext)
