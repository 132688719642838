import Img from '../Img'
import { trustpilotData } from '../TrustPilotWidget/const'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const TrustpilotDesign = ({
  className,
  trustpilotClassName,
  trustScoreClassName,
  trustpilotRatingClassName,
  starClassName,
  starsGroupClassName,
  starsGroupImgClassName,
  isHome = false,
  shortText = false,
  detailsText = false,
}) => {
  const reviewsNumber = trustpilotData.trustReviewsTotal
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')

  return (
    <>
      <div
        className={classNames(
          'flex flex-row items-center justify-center lg:justify-start',
          className
        )}
      >
        <Img
          src="/next-assets/trustpilot/big-star.webp"
          alt="Trustpilot 4.5 star average rating on over 38,000 reviews for Freedom Debt Relief"
          className={classNames('top-0', starClassName)}
        />
        <span
          className={classNames(
            'pl-1 pt-1 font-medium text-black-base',
            trustpilotClassName
          )}
        >
          Trustpilot
        </span>
        <Img
          src="/next-assets/trustpilot/trustpilot-rating.svg"
          alt="Trustpilot 4.5 star average rating on over 38,000 reviews for Freedom Debt Relief"
          className={starsGroupClassName}
          imgClassName={classNames('transform-none', starsGroupImgClassName)}
        />
        <span
          className={classNames(
            'pt-1 text-black-base',
            trustpilotRatingClassName
          )}
          data-testid={'trustpilot-rating'}
        >
          <span
            className={classNames(
              'text-text-black font-bold',
              trustScoreClassName
            )}
          >
            {trustpilotData.trustScore}
          </span>
          {shortText ? '/' : ' out of '}5{' '}
          {detailsText ? ` from ${reviewsNumber} reviews` : ''}
        </span>
      </div>
      {isHome ? (
        <span className="flex justify-center text-14 font-normal text-black-base">
          Reviews {reviewsNumber} · Excellent
        </span>
      ) : null}
    </>
  )
}

TrustpilotDesign.propTypes = {
  className: PropTypes.string,
  trustpilotClassName: PropTypes.string,
  trustScoreClassName: PropTypes.string,
  trustpilotRatingClassName: PropTypes.string,
  starClassName: PropTypes.string,
  starsGroupClassName: PropTypes.string,
  starsGroupImgClassName: PropTypes.string,
  isHome: PropTypes.bool,
  shortText: PropTypes.bool,
  detailsText: PropTypes.bool,
}

export default TrustpilotDesign
