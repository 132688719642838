import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { slider } from './index.module.scss'
import { numberFormat } from '../../../common/helpers'
import { useForm } from '../Form/useForm'
import classNames from 'classnames'

const Slider = ({
  min,
  max,
  step,
  start,
  onChange,
  name,
  id,
  valueClassName,
  sliderClassName,
  containerClassName,
  showMinMaxLabels,
  minMaxLabelsClassName,
  onSliderRelease,
}) => {
  const [sliderValue, setSliderValue] = useState(start)
  const { setValue } = useForm()
  const handleOnChange = useCallback(
    (event) => {
      setSliderValue(event.target.value)
      setValue?.('range', event.target.value)

      if (typeof onChange === 'function') {
        onChange(event)
      }
    },
    [setSliderValue, setValue, onChange]
  )

  return (
    <div className={classNames(slider, containerClassName, 'relative')}>
      <div className={valueClassName}>
        {`${numberFormat(sliderValue, { style: 'currency', currency: 'USD' })}`}
      </div>
      <input
        className={sliderClassName}
        style={{
          backgroundSize: `${((sliderValue - min) * 100) / (max - min)}%`,
        }}
        type="range"
        id={id}
        min={min}
        max={max}
        value={sliderValue}
        step={step < min ? step : min}
        aria-label="Slider"
        name={name}
        onChange={handleOnChange}
        onMouseUp={onSliderRelease}
        onTouchEnd={onSliderRelease}
      />
      {showMinMaxLabels ? (
        <>
          <div
            className={classNames(
              'absolute left-0 mt-1',
              minMaxLabelsClassName
            )}
          >
            {`${numberFormat(min, {
              style: 'currency',
              currency: 'USD',
            })}`}
          </div>
          <div
            className={classNames(
              'absolute right-0 mt-1',
              minMaxLabelsClassName
            )}
          >
            {`${numberFormat(max, { style: 'currency', currency: 'USD' })}+`}
          </div>
        </>
      ) : null}
    </div>
  )
}

Slider.defaultProps = {
  min: 1000,
  max: 100000,
  step: 1000,
  start: 25000,
  onChange: () => {},
  name: '',
  id: '',
  sliderLocation: '',
  valueClassName: 'mx-3 rounded bg-blue-900 p-1 text-xl text-white',
  sliderClassName:
    'mb-3.5 mt-8 h-2.5 w-full appearance-none rounded-lg bg-silver-100 bg-slider-track bg-no-repeat outline-none',
  containerClassName: '',
  showMinMaxLabels: false,
  minMaxLabelsClassName: 'text-sm text-gray-500',
  onSliderRelease: () => null,
}

Slider.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  start: PropTypes.number,
  onChange: PropTypes.func,
  name: PropTypes.string,
  id: PropTypes.string,
  sliderLocation: PropTypes.string,
  valueClassName: PropTypes.string,
  sliderClassName: PropTypes.string,
  containerClassName: PropTypes.string,
  showMinMaxLabels: PropTypes.bool,
  minMaxLabelsClassName: PropTypes.string,
  onSliderRelease: PropTypes.func,
}

export default Slider
